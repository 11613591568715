import { EntityId, FulfillmentTypes, PaymentMethods } from "@jackfruit/common"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LatLng } from "~/interfaces/entities/LatLng"
import { Payment } from "~/interfaces/entities/Payment"
import { UpdateStorePayload } from "~/redux/process"
import { carts, cartsSelector } from "~/redux/state/carts"
import { RootState } from "~/redux/store"
import { usePageSession } from "./usePageSession"
import { useProcessActions } from "./useProcessActions"

export const usePageCart = () => {
  const dispatch = useDispatch()
  const process = useProcessActions()
  const { pageSession } = usePageSession()
  const pageCart = useSelector((state: RootState) =>
    cartsSelector.selectById(state, pageSession?.cartId)
  )!

  const pageCartActions = useMemo(() => {
    return {
      removeAllLineItems: () => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              lineItemIds: [],
            },
          })
        )
      },
      setLineItems: (ids: EntityId[]) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              lineItemIds: ids,
            },
          })
        )
      },
      addLineItems: (ids: EntityId[]) => {
        const newLineItemIds = pageCart.lineItemIds.concat(ids)
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              lineItemIds: newLineItemIds,
            },
          })
        )
      },
      addLineItem: (id: EntityId) => {
        const newLineItemIds = [...pageCart.lineItemIds]
        newLineItemIds.push(id)
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              lineItemIds: newLineItemIds,
            },
          })
        )
      },
      removeLineItem: (id: EntityId) => {
        const newLineItemIds = pageCart.lineItemIds.filter(
          lineItemId => lineItemId !== id
        )
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              lineItemIds: newLineItemIds,
            },
          })
        )
      },
      setPrintServiceId: (id: EntityId) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              printServiceId: id,
            },
          })
        )
        process.updateOrderSummary({
          reason: "printServiceId changed",
          reasonType: "printServiceChanged",
        })
      },
      setFulfillment: (fulfillment: FulfillmentTypes) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              fulfillment: fulfillment,
            },
          })
        )
        process.updateOrderSummary({
          reason: "fulfillment changed",
          reasonType: "fulfillmentChange",
        })
      },
      setStore: (payload: UpdateStorePayload) => {
        process.updateStore(payload)
      },
      setStorePlace: (place: string) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              storePlace: place,
            },
          })
        )
      },
      setStoreLatLng: (latLng: LatLng) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              storeLat: latLng.lat,
              storeLng: latLng.lng,
            },
          })
        )
      },
      setPayment: (payment: Payment) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              payment,
            },
          })
        )
      },
      setPaymentMethod: (paymentMethod: PaymentMethods) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              payment: {
                type: paymentMethod,
                data: null, // That information will be populated before any form submission
              },
            },
          })
        )
      },
      setCoupon: (coupon: string) => {
        dispatch(
          carts.actions.updateOne({
            id: pageCart.id,
            changes: {
              couponCode: coupon,
            },
          })
        )
        process.applyCouponCode()
      },
    }
  }, [dispatch, pageCart?.id, pageCart?.lineItemIds, process])

  return { pageCart, pageCartActions }
}
