import { useDispatch, useSelector } from "react-redux"
import { Address } from "~/interfaces/entities/Address"
import { CartState } from "~/redux/state/cart"
import { RootState } from "~/redux/store"
import { usePageCart } from "./usePageCart"
import { cart as cartState } from "~/redux/state/cart"
import { User } from "~/interfaces/entities/User"
import { useMemo } from "react"
import { useProcessActions } from "./useProcessActions"

export const useCart = () => {
  const dispatch = useDispatch()
  const process = useProcessActions()
  const { pageCart, pageCartActions } = usePageCart()
  const cart = useSelector<RootState, CartState>(state => state.cart)

  const cartActions = useMemo(() => {
    return {
      setAddress: (address: Address) => {
        dispatch(cartState.actions.updateAddress(address))
        process.updateOrderSummary({
          reason: "address changed",
          reasonType: "addressChanged",
        })
      },
      setUser: (user: User) => {
        dispatch(cartState.actions.updateUser(user))
        process.updateOrderSummary({
          reason: "user info changed",
          reasonType: "userInfoChanged",
        })
      },
      ...pageCartActions,
    }
  }, [dispatch, pageCartActions, process])

  return {
    cart: {
      ...cart,
      ...pageCart,
    },
    cartActions,
  }
}
